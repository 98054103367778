import './globals';
import './methods';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';

import LogRocket from 'logrocket';
import { hotjar } from 'react-hotjar';
import * as Sentry from '@sentry/react';
import Pusher from 'pusher-js';
import { setPusherClient } from 'react-pusher';
import OneSignalReact from 'react-onesignal';
import OneSignalCordova from 'onesignal-cordova-plugin';

import classnames from 'classnames/dedupe';
import App from './App';
import packageJson from '../package';
import { initAxiosInterceptor, initAxiosRetry } from './utils/axiosAuthHelper';
import { pushNotificationsTags } from './pages/Settings/notifications';

import 'emoji-mart/css/emoji-mart.css';
import { SettingsStore } from './stores';

const env = process.env.NODE_ENV || 'development';
const apiKeys = require('../config/apiKeys.json')[env];
const config = require('../config/config.json')[env];

const pusherClient = new Pusher(config.pusher.app_key, { cluster: 'us2' });
setPusherClient(pusherClient);

window.Capacitor = window.Capacitor || { DEBUG: env === 'development', isLoggingEnabled: env === 'development', Plugins: {} };
window.OneSignalPlugin = window.Capacitor.isNative ? OneSignalCordova : OneSignalReact;

console.log('OneSignal:');
console.log(window.OneSignalPlugin);

(async () => {
  const App = lazy(() => import('./App'));
  ReactDOM.hydrate(
    <Suspense
      fallback={(
        <div>
          <nav
            className={
              classnames('rui-navbar rui-navbar-top')
            }
          >
            <div className="rui-navbar-brand" />
          </nav>
        </div>
      )}
    >
      <App />
    </Suspense>, document.getElementById('app')
  );
})();

// This sets these so we can view console logs in LogRocket, but they don't show to the user in the browser
console.log = LogRocket.log;
console.warn = LogRocket.warn;
console.error = LogRocket.error;

const loadFbLoginApi = () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: apiKeys.facebook,
      cookie: true, // enable cookies to allow the server to access
      xfbml: true, // parse social plugins on this page
      version: 'v9.0',
    });
  };

  // console.log('Loading fb api');
  // Load the SDK asynchronously
  (function (d, s, id) {
    let js; const
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
};
loadFbLoginApi();

if (config.hotjar.app_id) {
  hotjar.initialize(config.hotjar.app_id, 6);
}

// https://dev.to/devpato/push-notifications-in-reactjs-with-onesignal-5bon?utm_source=dormosheio&utm_campaign=dormosheio
(async () => {
  if (config.onesignal.app_id) {
    if (window.Capacitor.isNative) {
      window.OneSignalPlugin.setAppId('01ba0a17-1aea-4702-bc1a-af281308802b');
      // FIXME May want to add this to PushRequestPrompt to defer when this gets called until later, just like web implementation
      window.OneSignalPlugin.promptForPushNotificationsWithUserResponse(function(accepted) {
        console.log(`User accepted notifications: ${accepted}`);
      });
    } else {
      await window.OneSignalPlugin.init({
        appId: config.onesignal.app_id,
        safari_web_id: config.onesignal.safari_web_id,
        serviceWorkerParam: { scope: '/push/' },
        serviceWorkerPath: 'push/OneSignalSDKWorker.js',
        serviceWorkerUpdaterPath: 'push/OneSignalSDKUpdaterWorker.js',
        allowLocalhostAsSecureOrigin: env === 'development',
        autoResubscribe: true,
        promptOptions: {
          showCredit: false,
          slidedown: {
            // icon: '', // TODO What about the bell icon with the notification badge so they associate it with the UI element?
            autoPrompt: false,
            enabled: true,
            actionMessage: 'New alert available. Never miss an interaction by enabling push notifications.', // TODO Let them know they can disable them in settings?
            acceptButtonText: 'Enable and Show',
            cancelButtonText: 'Maybe Later',
            categories: {
              tags: pushNotificationsTags,
            },
            prompts: [
              {
                type: 'email',
                autoPrompt: false,
                text: {
                  smsLabel: 'Insert Phone Number',
                  acceptButton: 'Submit',
                  cancelButton: 'No Thanks',
                  actionMessage: 'Receive the latest news, updates and offers as they happen.',
                  updateMessage: 'Update your push notification subscription preferences.',
                  confirmMessage: 'Thank You!',
                  negativeUpdateButton: 'Cancel',
                  positiveUpdateButton: 'Save Preferences',
                  emailLabel: 'Email Address',
                },
                delay: {
                  pageViews: 1,
                  timeDelay: 20,
                },
              },
            ],
          },
        },
        welcomeNotification: {
          title: 'Boxpressd Push Notifications Enabled',
          message: 'Your alerts are now set up for the categories you selected.',
        },
      });
    }
  }
})();

// if (ls('boxpressd_token_id')) {
//   axios.defaults.headers.common.Authorization = `Bearer ${ls('boxpressd_token_id')}`;
// }

// StatusBar.setOverlaysWebView({ overlay: false });

// FIXME Also look into something like https://stackoverflow.com/questions/31468214/prevent-preflight-options-when-using-sub-domains/41797870 to avoid preflight OPTIONS
// https://medium.com/@praveen.beatle/avoiding-pre-flight-options-calls-on-cors-requests-baba9692c21a

initAxiosInterceptor();
initAxiosRetry();

const blacklistedErrorMessages = [
  // FIXME These are a problem in react-virtuoso - https://github.com/petyosi/react-virtuoso/issues/254
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
  'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
  'The operation is insecure.',
  'Error: Error in API: 0',
];

if (process.env.NODE_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
  console.log(window.location.host);
  Sentry.init({
    dsn: 'https://b54915aadd41409585b08abe7cbce771@sentry.io/2448864',
    environment: process.env.NODE_ENV,
    release: packageJson.version,
    ignoreErrors: blacklistedErrorMessages,
    beforeSend(event, hint) {
      const eventId = hint.event_id;
      SettingsStore.update((s) => {
        s.lastErrorId = eventId;
      });
      const error = hint.originalException;
      console.error(error);
      if (
        error
        && error.message
        && blacklistedErrorMessages.indexOf(error.message) !== -1
      ) {
        return null;
      }
      return event;
    },
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}

// if (Capacitor.platform.is('ios')) {
//   SentryNative.initialize({
//     dsn: '',
//     environment: process.env.NODE_ENV, // FIXME From iOS build
//     release: packageJson.version,
//   });
// } else if (this.platform.is('android')) {
//   SentryNative.initialize({
//     dsn: '',
//     environment: process.env.NODE_ENV, // FIXME From Gradle build
//     release: packageJson.version,
//   });
// }
